<template>
  <b-form-group label="Szervezetek">
    <ul class="list-group dense scrollable-list">
      <li
        v-for="szervezet in visibleSzervezetek"
        :key="szervezet.Id"
        class="list-group-item d-flex justify-content-between align-items-center hidden-container"
      >
        <div class="d-flex align-items-center flex-fill">
          <k-file-select
            accept="image/*"
            :block="false"
            class="mr-2"
            @inputBase64="szervezet.Kep = $event[0]"
          >
            <img
              class="li-img border-radius-6"
              :src="szervezet.Kep ? szervezet.Kep + '?h=30' : $placeholderImage"
            />
          </k-file-select>
          <k-inline-text-edit :value.sync="szervezet.Nev" :text="szervezet.Nev">
          </k-inline-text-edit>
        </div>
        <div
          class="li-tile mr-2 pointer hidden-item"
          @click="RemoveSzervezet(szervezet)"
          v-b-tooltip="'Törlés'"
        >
          <i class="fa fa-trash"></i>
        </div>
      </li>
      <li
        class="list-group-item d-flex justify-content-start align-items-center pointer sticky-bottom"
        @click="UjSzervezet"
      >
        <div class="li-tile grey mr-2">
          <i class="fa fa-plus"></i>
        </div>
        <span>
          Új szervezet
        </span>
      </li>
    </ul>
  </b-form-group>
</template>

<script>
  /* eslint vue/no-mutating-props: 0 */
export default {
  name: 'pcf-szervezetek',
  data() {
    return {
      ct: -1,
    };
  },
  mounted() {},
  created() {},
  methods: {
    async UjSzervezet() {
      let id = this.ct--;
      this.form.Szervezetek.push({
        Id: id,
        Nev: `Szervezet${id}`,
        Kep: null,
        ToroltFl: false,
      });
      await this.$nextTick();
      this.$el.getElementsByClassName('scrollable-list')[0].scrollTop =
        Number.MAX_SAFE_INTEGER;
    },
    RemoveSzervezet(szervezet) {
      if (szervezet.Id > 0) {
        let prev = this.form.Szervezetek.find(f => f.Id == szervezet.Id);
        this.$set(prev, 'ToroltFl', true);
      } else {
        this.form.Szervezetek = this.form.Szervezetek.filter(
          f => f.Id != szervezet.Id
        );
      }
    },
  },
  computed: {
    visibleSzervezetek() {
      return this.form.Szervezetek.filter(f => !f.ToroltFl);
    },
  },
  watch: {},
  props: {
    form: {
      requied: true,
    },
  },
};
</script>
